<template>
  <el-dialog
    title="思锐ERP解绑确认"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    width="440px"
    @close="close"
  >
    <div class="content-box">
      <div class="font-weight-bold text-center">是否确认解除绑定思锐ERP？</div>

      <el-form ref="form" :model="formData" label-width="100px" size="small" class="m-t-30">
        <el-form-item label="账套管理员：" prop="account">
          <div>{{ formData.managerName }} {{ `（${formData.managerAccount}/${formData.managerPhone}）` }}</div>
        </el-form-item>
        <el-form-item label="手机验证码：" prop="smsCaptcha">
          <SMSCode
            v-model="formData.smsCaptcha"
            :smsCaptcha="formData.smsCaptcha"
            :phone="formData.managerPhone"
            :type="formData.type"
          />
        </el-form-item>
      </el-form>
    </div>
    <div class="m-t-40 text-center font-size-12 text-999">
      解绑说明：商城的订单将不自动同步到该ERP，且订单状态将不同步更新
    </div>
    <div slot="footer">
      <el-button size="small" plain @click="close">取消</el-button>
      <el-button size="small" type="primary" @click="confirm">确认解绑</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import SMSCode from "@/components/SMSCode";
import { unbindErpAccountSets } from "@/api/associatedErp";

export default {
  name: "UnbindErpBox",
  components: {
    SMSCode
  },
  data() {
    return {
      desc: "思锐ERP解绑确认",
      formData: {
        managerName: "李明",
        managerAccount: "001@001",
        managerPhone: "17770479608",
        type: "unbindErp",
        smsCaptcha: ""
      },
      visible: false
    };
  },
  computed: {
    ...mapState("user", {
      bindErp: state => {
        return state.userInfo.erp;
      }
    })
  },
  watch: {
    bindErp: {
      handler(val) {
        if (val) {
          this.formData.managerName = val.managerName;
          this.formData.managerAccount = val.managerAccount;
          this.formData.managerPhone = val.managerPhone;
        }
      },
      immediate: true
    }
  },
  methods: {
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    confirm() {
      if (!this.formData.smsCaptcha) {
        this.$message.error("请输入短信验证码");
        return;
      }
      const params = {
        phone: this.formData.managerPhone,
        type: this.formData.type,
        smsCaptcha: this.formData.smsCaptcha
      };
      unbindErpAccountSets(params)
        .then(() => {
          this.$message.success("解绑成功");
          this.close();
          this.$store.dispatch("user/getUserInfo");
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    }
  }
};
</script>
<style lang="less" scoped>
.qrcode-img {
  width: 160px;
  height: 160px;
  padding: 5px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
</style>
