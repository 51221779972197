import request from "@/utils/request";

/**
 * 帐套分公司列表
 */
export function getAccountSetsList(params) {
  return request({
    url: "/mall/account-sets/list",
    method: "get",
    params
  });
}

/**
 * 绑定ERP
 */
export function bindErpAccountSets(data) {
  return request({
    url: "/mall/account-sets/bind-erp",
    method: "post",
    data: data
  });
}

/**
 * 解除绑定ERP
 */
export function unbindErpAccountSets(data) {
  return request({
    url: "/mall/account-sets/unbind-erp",
    method: "post",
    data: data
  });
}
