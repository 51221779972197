<template>
  <div class="associatedErp client-page p-tb-16 p-lr-20 flex-column">
    <div class="p-tb-20 title-box text-center">
      关联思锐ERP
    </div>
    <div class="flex1 flex flex-col-center overflow-auto">
      <div class="form-box m-t-20" v-if="!bindErp">
        <el-form
          :model="ruleForm"
          :rules="rules"
          :show-message="false"
          ref="associatedErp"
          label-width="110px"
          class="ruleForm m-tb-20"
        >
          <el-form-item label="管理员手机号" prop="phone">
            <el-input
              v-model.trim="ruleForm.phone"
              type="text"
              maxlength="11"
              clearable
              placeholder="请输入管理员手机号"
            />
          </el-form-item>
          <el-form-item label="图形验证码" prop="imgCaptcha">
            <el-input v-model.trim="ruleForm.imgCaptcha" placeholder="请输入图形验证码" maxlength="6" clearable>
              <template slot="append">
                <div class="flex flex-center cursor-pointer" @click.stop="getCaptchasImgFn">
                  <img :src="captchasImgUrl" class="h-35" alt="图形验证码" />
                </div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="手机验证码" prop="smsCaptcha">
            <SMSCode
              v-model.trim="ruleForm.smsCaptcha"
              class="smsCaptcha"
              :type="ruleForm.type"
              :smsCaptcha="ruleForm.smsCaptcha"
              :phone="ruleForm.phone"
            />
          </el-form-item>
          <el-form-item label="选择账套" prop="dbAccount">
            <el-select
              v-model="ruleForm.dbAccount"
              clearable
              placeholder="请选择"
              @focus="queryAccountsList"
              @change="dbAccountChange"
            >
              <el-option
                v-for="item in accountsOptions"
                :key="item.id"
                :label="`${item.name}(${item.source})`"
                :value="item.dbAccount"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择分公司" prop="companyId">
            <el-select
              v-model="ruleForm.companyId"
              clearable
              placeholder="请选择"
              :disabled="!subCompanyOptions.length"
              @change="subCompanyChange"
            >
              <el-option v-for="item in subCompanyOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="m-t-50 text-center text-666 font-size-12">
          绑定说明：商城的订单将自动同步到该ERP，且订单状态同步更新
        </div>
        <div class="m-t-40 p-lr-20">
          <el-button round type="danger" class="width-full" @click="onBind">立即绑定</el-button>
          <!-- <el-button round type="danger" class="width-full" @click="onBind">立即绑定(Alt+F4)</el-button> -->
        </div>
      </div>

      <div class="form-box flex-column flex-row-center" v-else>
        <div style="width:400px;margin-top: 100px;">
          <div class="m-t-20 flex flex-center">
            <div class="w-100 text-right">绑定账套：</div>
            <div class="flex1 text-center">{{ bindErp.accountSetName }}</div>
          </div>
          <div class="m-t-20 flex flex-center">
            <div class="w-100 text-right">绑定分公司：</div>
            <div class="flex1 text-center">{{ bindErp.companyName }}</div>
          </div>
          <div class="m-t-20 flex flex-center">
            <div class="w-100 text-right">账套管理员：</div>
            <div class="flex1 text-center">
              {{ bindErp.managerName }} {{ `（${bindErp.managerAccount}/${bindErp.managerPhone}）` }}
            </div>
          </div>
        </div>
        <div class="width-full m-t-40 p-lr-40 box-sizing">
          <el-button round type="danger" class="width-full" @click="onUnbind">解除绑定</el-button>
        </div>
      </div>
    </div>
    <!-- 思锐ERP解绑确认 -->
    <UnbindErpBox ref="UnbindErpBox" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import SMSCode from "@/components/SMSCode";
import UnbindErpBox from "./components/UnbindErpBox.vue";
import { captchaValidate } from "@/utils/validate";
import { getCaptchasImg } from "@/api/captchas";
import { getAccountSetsList, bindErpAccountSets } from "@/api/associatedErp";

const defaultForm = {
  phone: "",
  imgCaptcha: "",
  type: "bindErp",
  smsCaptcha: ""
};
export default {
  name: "associatedErp",
  components: {
    SMSCode,
    UnbindErpBox
  },
  data() {
    return {
      keyName: "alt+f4",
      keyScope: "ManageAssociated",
      captchasImgUrl: "",
      ruleForm: {
        ...defaultForm
      },
      erp: {},
      rules: {
        phone: [{ required: true, message: "请输入管理员手机号", trigger: "blur" }],
        imgCaptcha: [{ required: true, message: "请输入图形验证码", trigger: "blur" }],
        smsCaptcha: [{ required: true, validator: captchaValidate, trigger: "blur" }],
        dbAccount: [{ required: true, message: "请选择账号", trigger: "change" }],
        companyId: [{ required: true, message: "请选择分公司", trigger: "change" }]
      },
      accountsOptions: [
        // {
        //   source: "m8", // 帐套类型 m8/saas
        //   serverUrl: "serverUrl", // 服务器地址
        //   dbAccount: "dbAccount", // 帐套号
        //   name: "成都东方思锐", // 帐套名称
        //   companies: [
        //     // 分公司列表
        //     {
        //       id: 1, // 公司ID
        //       name: "成都东方思锐1分公司", // 公司名称
        //       type: "12" // 公司类型
        //     }
        //   ]
        // }
      ],
      subCompanyOptions: []
    };
  },
  computed: {
    ...mapState("user", {
      bindErp: state => {
        return state.userInfo.erp;
      }
    })
  },
  mounted() {
    this.getCaptchasImgFn();
    setTimeout(() => {
      this.$hotkeys(this.keyName, this.keyScope, this.handleKeyCode);
      this.$hotkeys.setScope(this.keyScope);
    }, 500);
  },
  beforeDestroy() {
    this.$hotkeys.unbind(this.keyName, this.keyScope);
  },
  methods: {
    // 图形验证码
    getCaptchasImgFn() {
      const params = {
        id: sessionStorage.getItem("captchasImgId") || ""
      };
      getCaptchasImg(params)
        .then(res => {
          sessionStorage.setItem("captchasImgId", res.id);
          this.captchasImgUrl = `data:image/png;base64,${res.image}`;
        })
        .catch(err => {
          this.$message.error(err?.message || "获取失败");
        });
    },
    checkItem() {
      if (!this.ruleForm.phone) {
        this.$message.error("请输入管理员手机号");
        return false;
      }
      if (!this.ruleForm.imgCaptcha) {
        this.$message.error("请输入图形验证码");
        return false;
      }
      if (!this.ruleForm.smsCaptcha) {
        this.$message.error("请输入短信验证码");
        return false;
      }
      return true;
    },
    // 账套分公司列表
    queryAccountsList() {
      if (!this.checkItem()) return;
      const params = {
        phone: this.ruleForm.phone, // 管理员手机号
        imgId: sessionStorage.getItem("captchasImgId"), // 图形ID
        imgCaptcha: this.ruleForm.imgCaptcha, // 图形验证码
        type: this.ruleForm.type, // 场景 bindErp: 绑定ERP
        smsCaptcha: this.ruleForm.smsCaptcha // 短信验证码
      };
      getAccountSetsList(params)
        .then(res => {
          this.accountsOptions = res || [];
        })
        .catch(err => {
          this.$message.error(err?.message || "获取失败");
        });
    },
    dbAccountChange(val) {
      if (!val) {
        this.erp = {};
        this.subCompanyOptions = [];
        this.ruleForm.companyId = null;
      }
      this.accountsOptions.forEach(p => {
        if (val === p.dbAccount) {
          this.erp = {
            source: p.source, // 来源 m8/saas
            serverUrl: p.serverUrl, // 服务器地址
            dbAccount: p.dbAccount // 帐套
          };
          this.subCompanyOptions = p.companies;
        }
      });
    },
    subCompanyChange(val) {
      this.erp.companyId = val;
    },
    handleKeyCode(e) {
      switch (e.code) {
        case "F4":
          e.preventDefault();
          e.stopPropagation();
          if (e.altKey) {
            this.onBind();
          }
          break;
      }
    },
    //刷新用户信息
    getUserInfo() {
      this.$store.dispatch("user/getUserInfo");
    },
    // 绑定
    onBind() {
      this.$refs.associatedErp.validate((valid, err) => {
        if (valid) {
          //调用新增接口
          const params = {
            phone: this.ruleForm.phone, // 管理员手机号
            imgId: sessionStorage.getItem("captchasImgId"), // 图形ID
            imgCaptcha: this.ruleForm.imgCaptcha, // 图形验证码
            type: this.ruleForm.type, // 场景 bindErp: 绑定ERP
            smsCaptcha: this.ruleForm.smsCaptcha, // 短信验证码
            erp: {
              ...this.erp
            }
          };
          bindErpAccountSets(params)
            .then(() => {
              this.$message.success("绑定成功");
              this.getUserInfo();
              this.erp = {};
              this.subCompanyOptions = [];
              this.ruleForm = {
                ...defaultForm
              };
            })
            .catch(err => {
              this.$message.error(err?.message || "绑定失败");
            });
        } else {
          const errTips = err[Object.keys(err)[0]][0].message;
          this.$message.warning({ message: errTips, showClose: true });
        }
      });
    },
    // 解除绑定
    onUnbind() {
      this.$refs.UnbindErpBox.open();
    }
  }
};
</script>

<style lang="less" scoped>
.associatedErp {
  .title-box {
    font-size: 16px;
    font-weight: bold;
    background: #eeeeee;
    border-radius: 4px;
  }
  .form-box {
    width: 600px;
  }
  .smsCaptcha {
    ::v-deep.el-input-group__append {
      width: 105px;
      text-align: center;
    }
  }
}
</style>
